import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function Swap() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">0xSwap DEX</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={8} xl={8}>
                                <div>
                                    0xSwap is a platform that allows you to create a custom swap website for your own token, providing seamless integration with decentralized exchanges.
                                </div>
                                <div>
                                    It offers user-friendly tools and templates to quickly set up and manage token swaps with no coding required. With robust security features and compatibility with BSC blockchain, 0xSwap ensures reliable and efficient transactions for your token's ecosystem.
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h1>Your Token & BNB Swap</h1>
                                </div>
                                <br/>
                                <h4>Details : </h4>
                                <div>- Swap between your token & BNB only</div>
                                <div>- Based on PancakeSwap Router V2</div>
                                <div>- Support BSC Network</div>
                                <div>- Trading Chart</div>
                                <div>- Demo : <a href="https://swap.0xfactory.com/" target="_blank" rel="noreferrer">https://swap.0xfactory.com</a></div>
                                <br/>
                                <div className="text-center">
                                <a href="https://bit.ly/0xSwap" target="_blank" rel="noreferrer"><h3 style={{color: 'yellow'}}>Download Now</h3></a>
                                </div>
                                <br/>
                            </Col>
                            <Col xs={12} sm={4} xl={4}>
                                <Box>
                                    <div className="text-center">
                                        <img src="images/0xswap.png" width={"100%"} alt="Dex" />
                                    </div>
                                    <br/>
                                </Box>
                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}