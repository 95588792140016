import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box } from "../../components/elements";
import { useSearchParams } from "react-router-dom";

export default function ContractAirdrop() {

    const [searchParams] = useSearchParams();

    return (
        <PageLayout>
            <Box className="mc-blank">
                <h1>Congratulations</h1>
                <h2>Your airdrop contract has been created</h2>
                <br/>
                <h3>{searchParams.get('id')}</h3>
                <br/>
                <br/>
                <br/>
                Next step : send your airdrop tokens to the above airdrop contract and download the Airdrop UI app.
                <br/>
                <h3><a href="https://0xfactory.com/download/Airdrop-UI.zip" target="_blank" rel="noreferrer">Download Airdrop UI</a></h3>
                <br/>
                <h3><a href="https://0xfactory.com/download/Airdrop-UI-Aff.zip" target="_blank" rel="noreferrer">Download Airdrop with Affiliate UI</a></h3>
                <br/>
                <br/>
            </Box>
        </PageLayout>
    )
}
