import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box } from "../../components/elements";
import { useSearchParams } from "react-router-dom";

export default function StableSwapContract() {

    const [searchParams] = useSearchParams();


    return (
        <PageLayout>
            <Box className="mc-blank">
                <h1>Congratulations</h1>
                <h2>Your stable swap contract has been created</h2>
                <br/>
                <h3>{searchParams.get('id')}</h3>
                <br/>
                <br/>
                <br/>
                Next step : download the fornt end and finish it's configurations.
                <br/>
                <h3><a href="https://bit.ly/0xStableSwap" target="_blank" rel="noreferrer">Download Front-End</a></h3>
            </Box>
        </PageLayout>
    )
}
