import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Icon, Heading } from "../../components/elements";

export default function ComingSoon() {
    return (
        <PageLayout>
            <Box className="mc-blank">
                <Icon type="timer" />
                <Heading as="h2" className="mc-overview-title">Coming Soon</Heading>
            </Box>
        </PageLayout>
    )
}