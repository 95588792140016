import React from "react";
import { Image, List, Box, Text, Item, Anchor, Button } from "../../components/elements";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";

export default function UtilContracts() {
    return (
        <PageLayout>
            <CardLayout className="mb-4">
                <Breadcrumb title="Download Smart Contracts - Full Solidity Code"></Breadcrumb>
            </CardLayout>

            <CardLayout>
                <List className="mc-notify-list">
                    
                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/burn.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Buy & Burn Contract ( Price: $15 )</Text>
                                    <Text as="small">Buy & Burn Contract, this smart contract is designed to create a deflationary mechanism for the token, reducing the overall supply over time and potentially increasing its value for investors.</Text>
                                </Box>
                            <Anchor href="https://pay.0xfactory.com/pay.php?checkout_id=9">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/divider.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">BNB Divider Contract ( Price: $15 )</Text>
                                    <Text as="small">The BNB Divider smart contract is a decentralized application (DApp) built on the Binance Smart Chain (BSC) that facilitates the division and distribution of BNB tokens among multiple participants.</Text>
                                </Box>
                            <Anchor href="https://pay.0xfactory.com/pay.php?checkout_id=10">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                        </Item>

                        
                </List>
            </CardLayout>
        </PageLayout>
    )
}