import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function Trading() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">Join Our Trading Bot</Heading>
                    <div className="text-center">
                        <h1 style={{color: 'yellow'}}>Next Round: 1-30 July</h1>
                        <br />
                    </div>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={8} xl={8}>
                                <div>
                                    The 0xFactory Trading Bot is a unique program tailored for USDX holders, designed to generate profits through strategic trading activities.
                                </div>
                                <div>
                                    0xFactory Trading Bot is its profit-sharing mechanism, where USDX holders receive 50% of the profits generated.
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h1>Hold USDX And Earn Monthly Income</h1>
                                    <h3 style={{color: 'yellow'}}>Estimate Profit %10-%15 Monthly</h3>
                                </div>
                                <br/>
                                <h4>Rules : </h4>
                                <div>- Hold USDX from day 1 to day 30.</div>
                                <div>- Hold at least 100 USDX.</div>
                                <div>- You can swap USDX to USDT anytime.</div>
                                <div>- Swaping USDX during the trading period will cancel your membership.</div>
                                <div>- Profit distribution in 30th of every month.</div>
                                <br/>
                                <br/>
                                <div className="text-center">
                                <a href="https://usdx.0xfactory.com/" target="_blank" rel="noreferrer"><h3 style={{color: 'yellow'}}>Get USDX</h3></a>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/mn_8MyapSm8?si=KnrreP6xaPhBLtOh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <br/>
                                <div className="text-center">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/Lm9Aqb0tp0Q?si=sk2xhAG_HZ2xSXvj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} xl={4}>
                                <Box>
                                    <div className="text-center">
                                        <img src="images/bot.png" width={"100%"} alt="bot" />
                                    </div>
                                    <br/>
                                </Box>
                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}