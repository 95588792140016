import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function ZxfRu() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">Частная продажа ZXF</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={8} xl={8}>
                                <div>
                                Токен ZXF — это криптовалюта, на которой работает платформа 0xFactory, вы можете не только торговать ZXF, как и любой другой криптовалютой, вы также можете использовать ZXF в самых разных приложениях и вариантах использования.
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h2>Владельцы ZXF получают 50% ежемесячной прибыли 0xFactory</h2>
                                    <h3>Мы будем покупать и сжигать ежемесячно</h3>
                                </div>
                                <br/>
                                <h4>Частная продажа: </h4>
                                <div>Дата: 24 Маршировать - 7 апрель</div>
                                <div>Цена: $0.00027</div>
                                <br/>
                                <h4>Панель запуска на BitMart: </h4>
                                <div>Дата: 9 апрель (24 hours)</div>
                                <div>Цена: $0.0003</div>
                                <br/>
                                <h4>Листинг на BitMart: </h4>
                                <div>Дата: 13 апрель</div>
                                <div>Цена: $0.00033</div>
                                <br/>
                                <h3>ZXF Tokenomics: </h3>
                                <div>Total: 1000,000,000</div>
                                <div>Private sale: 150,000,000</div>
                                <div>BitMart launchpad: 150,000,000</div>
                                <div>Liquidity: 225,000,000 (Locked 5 years)</div>
                                <div>Marketing: 200,000,000 (Vested : Release 3,333,000/month for 60 month)</div>
                                <div>Development: 175,000,000 (Vested : Release 2,916,000/month for 60 month)</div>
                                <div>Team: 100,000,000 (Vested : Release 1,666,000/month for 60 month)</div>
                                <h4>Contract: <a href="https://bscscan.com/token/0xe6d7078798edf8c6ec129ed71dccd3ec29b3dfc8" target="_blank" rel="noreferrer">0xe6d7078798edf8C6eC129ed71Dccd3Ec29B3DFc8</a></h4>
                                <h6>NO buy or sell tax</h6>
                            </Col>
                            <Col xs={12} sm={4} xl={4}>
                                <Box>
                                    <div className="text-center">
                                        <h3>Купить токен ZXF</h3>
                                        Скопируйте адрес частной продажи или отсканируйте QR-код и отправьте BNB, чтобы получить токен ZXF.
                                    </div>
                                    <br/>
                                    <div className="text-center">
                                    0x5677b577eb8ac274596f2B5fD6677c304Def1879
                                    </div>
                                    <br/>
                                    <div className="text-center">
                                        <img src="images/qr.png" width={"75%"} alt="QR Code" />
                                    </div>
                                    <br/>
                                    <div className="text-center">
                                        <h3>Ставка: 1 BNB = 1,222,222 ZXF</h3>
                                    </div>
                                </Box>
                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}