import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Anchor, Box, Image, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";
import data from "../../data/supports/overview.json";

export default function PremiumApps() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">Ready Dapps</Heading>
                    <div className="text-center">
                        <h3>Ready dapps is the same free dapps, but i will create the contracts and config the UI for you and give you the dapp full ready to upload, our dapps is built files and no source code for the UI or the smart contract will provided.</h3>
                    </div>
                    <br/>
                    <Row xs={1} sm={2} xl={3}>
                        {data?.premiumApps.map((demo, index) => (
                            <Col key={index} >
                                <Box className="mc-overview-card">
                                    <Heading className="mc-overview-name">{demo.title}</Heading>
                                    <Box className="mc-overview-media">
                                        <Image src={demo.image} alt="demo" />
                                        <h3>{demo.price}</h3>
                                        <p>
                                            {demo.description}
                                        </p>
                                        <Box className="mc-overview-overlay">
                                            <Anchor href={demo.path} rel="noopener noreferrer" className="mc-overview-link">Download Now</Anchor>
                                        </Box>
                                    </Box>
                                </Box>
                            </Col>
                        ))}
                    </Row>
                    
                </Container>
        </PageLayout>
    )
}