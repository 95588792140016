import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function Zxf() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">ZXF Private Sale</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={12} xl={12}>
                                <div>
                                    ZXF token is the cryptocurrency that powers the 0xFactory platform, not only can you trade ZXF like any other cryptocurrency, you can also use ZXF in a wide range of applications and use cases.
                                </div>
                                <br/>
                                <div className="text-center">
                                <a href="https://t.me/mohamedmox" target="_blank" rel="noreferrer"><h2 style={{color:'yellow'}}>Special Offers For Investors, vaild untile 20 SEP 2023</h2></a>
                                <br/>
                                <br/>
                                <h3 style={{color:'yellow'}}>Hold 300,000 ZXF And Get Free 0xFactory White Label</h3>
                                <br/>
                                <h3 style={{color:'yellow'}}>Hold 200,000 ZXF And Get Free DEX V2</h3>
                                <br/>
                                <h3 style={{color:'yellow'}}>Hold 133,000 ZXF And Get Free Premium Tokens Creator</h3>
                                <br/>
                                <h3 style={{color:'yellow'}}>Hold 66,666 ZXF And Get Free DEX V1</h3>
                                <br/>
                                <h3 style={{color:'yellow'}}>Hold 66,666 ZXF And Get Free Premium ICO Launchpad</h3>
                                <br/>
                                <h3 style={{color:'yellow'}}>Hold 50,000 ZXF And Get Free Premium Airdrop</h3>
                                <br/>
                                <h3 style={{color:'yellow'}}>Hold 13,000 ZXF And Get Free 8 Token Contracts</h3>
                                </div>
                                
                                <br/>
                                <div className="text-center">
                                        <img src="images/ico.png" alt="QR Code" />
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h2 style={{color:'yellow'}}>Private sale End: 20 SEP 2023</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <a href="https://launchpad.0xfactory.com/" target="_blank" rel="noreferrer"><h1 style={{color:'white'}}>Click here to buy</h1></a>
                                </div>
                                <br/>
                                <h2>Rates: </h2>
                                <div><h3>Private Sale Rate: 1 BNB = 66,666 ZXF (End 20 SEP 2023)</h3></div>
                                <br/>
                                <div><h3>Presale Rate: 1 BNB = 22,222 ZXF (Start 21 SEP 2023 - End 20 OCT 2023)</h3></div>
                                <br/>
                                <div><h3>Listing Rate: 1 BNB = 16,667 ZXF (21 OCT 2023)</h3></div>
                                <br/>
                                <h3>ZXF Tokenomics: </h3>
                                <div>Total Supply: 100,000,000</div>
                                <div>Private sale: 1,600,000</div>
                                <div>Presale: 28,400,000</div>
                                <div>Liquidity: 22,500,000 (Locked 5 years)</div>
                                <div>Marketing: 20,000,000 (Vested : Release 333,000/month for 60 month)</div>
                                <div>Development: 17,5000,000 (Vested : Release 300,000/month for 60 month)</div>
                                <div>Team: 10,000,000 (Vested : Release 166,000/month for 60 month)</div>
                                <h4><a href="https://bscscan.com/token/0x4f214BF2EBd847b6C990Dd96EE8a76e7aEC5E7CD" target="_blank" rel="noreferrer">ZXF on BscScan.com</a></h4>
                                <h6>NO Tax</h6>
                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}