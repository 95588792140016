import React from "react";
import { useEffect, useState } from 'react';
import PageLayout from "../../layouts/PageLayout";
import { DivideTitle } from "../../components";
import { Row, Col } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { LabelField } from "../../components/fields";
import { Box, Button } from "../../components/elements";
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';
import { useNavigate } from "react-router-dom";

export default function EasySell() {

    const contractAddress = "0xDaD73244Fb8bbCe99Ab33604397b12DdF7Bb50D3";
    const abi = [{"inputs":[{"internalType":"uint256","name":"_commission","type":"uint256"},{"internalType":"address","name":"_token","type":"address"},{"internalType":"address payable","name":"_myWallet","type":"address"}],"name":"createEasySell","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[],"name":"getNewEasySell","outputs":[{"internalType":"contract EasySell","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"newEasySell","outputs":[{"internalType":"contract EasySell","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address payable","name":"","type":"address"}],"stateMutability":"view","type":"function"}];
    const fee = "15000000000000000";

    let myNewContract = "";
    let tempNewContract = "";
    const navigate = useNavigate();

    // 4. Use modal hook
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

    const [ContractText, setContractText] = useState(null);
    const [WalletText, setWalletText] = useState(null);
    const [CommissionText, setCommissionText] = useState("0");
    const [isLoading, setIsLoading] = useState(false);
    const [createButtonText, setCreateButtonText] = useState("Create Contract")
    const [createButtonIcon, setCreateButtonIcon] = useState("add")
    const [createButtonColor, setCreateButtonColor] = useState("mc-btn green m-2")



      const handleContractChange = (event) => {
        setContractText(event.target.value);
      };

      const handleWalletChange = (event) => {
        setWalletText(event.target.value);
      };

      const handleCommissionChange = (event) => {
        setCommissionText(event.target.value);
        // console.log(event.target.value);
      };




      const connectWalletButton = () => {
        return (
          <Button onClick={() => open()} icon="wallet" text="Wallet Connect" className="mc-btn primary m-2" />
        )
      }

      const createButton = () => {
        return (
          <Button onClick={createNewContract} icon={createButtonIcon} text={createButtonText} className={createButtonColor} disabled={isLoading} />
        )
      }


      useEffect(() => {

      }, [])

      function timeout() {
        return new Promise( res => setTimeout(res, 5000) );
      }

      const createNewContract = async() => {
        try{
          

          if(isConnected){
            const provider = new BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            const contract = new Contract(contractAddress, abi, signer);

            console.log("Intialize payment");
            let newEasySell = await contract.createEasySell(CommissionText, ContractText, WalletText, {value: fee});
            tempNewContract = await contract.getNewEasySell();
            myNewContract = tempNewContract;

            setIsLoading(true);
            setCreateButtonText("Please Wait...");
            setCreateButtonIcon("timer");
            setCreateButtonColor("mc-btn red m-2");

            while(tempNewContract === myNewContract){
              myNewContract = await contract.getNewEasySell();
              await timeout();
            }
        if(newEasySell){
          navigate(`/token-affiliate-contract?id=${myNewContract}&chain=bsc`);
          // alert("Congratulations, your ico launchpad has been created on smart chain testnet.");
          // alert(myNewContract);
          //  console.log(myNewContract);
        }else{
          alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
        }
          }
        }catch(err){
        alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
          console.log(err);
        }

      }

    return (
        <PageLayout>
            <Row>
            <Col xl={12}>
                    <Box className="mc-card">
                        <CardHeader title="Token Affiliate Contract : Sell any token and earn commission" />
                        <div>This contract will buy the token automatically when receive BNB and send it to the sender wallet.</div>
                        <div>This contract will send your commssion to your wallet immediately after the user receive his tokens.</div>
                        <div>Working only for token on binance smart chain which have liquidity on pancake swap with BNB.</div>
                        <div>0.0005 BNB fee per transaction.</div>
                        <Row xs={12} md={12} xl={12}>
                            <Col>
                                <DivideTitle title=" " className="mb-4 mt-3" />
                                <LabelField onChange={handleWalletChange} value={WalletText} label="Your Wallet" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a.............." />
                                <LabelField onChange={handleContractChange} value={ContractText} label="Token Contract" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a.............." />
                                <LabelField onChange={handleCommissionChange} value={CommissionText} label="Select Commission %" fieldSize="w-100 h-sm" labelDir="label-col mb-4" option={["0", "1", "2", "3", "4", "5"]} />
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xl={12}>
                    <Box className="mb-4">
                        <Row xs={12} md={3} xl={3} className="justify-content-center">
                          {isConnected ? createButton() : connectWalletButton()}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}
