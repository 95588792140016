import React from 'react';
import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import detectEthereumProvider from '@metamask/detect-provider';
import axios from 'axios';
import abi from './abi/payment';
import pancakeSwapRouterABI from './abi/pancakeSwapRouterABI.json';
import ERC20ABI from './abi/ERC20ABI.json';
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';



const PaymentModal = ({ show, handleClose, downloadLink, price }) => {

    // 4. Use modal hook
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

    const [bnbPrice, setBnbPrice] = useState(null);

    const paymentContract =  '0xEC19d08673c63a70857418b4F82184AD460C5991';
    const fee = '500000000000000';
    const provider = new JsonRpcProvider('https://bsc-dataseed.binance.org/');
    const routerAddress = '0x10ED43C718714eb63d5aA57B78B54704E256024E';
    const bnbAddress = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c';
    const usdtAddress = '0x55d398326f99059fF775485246999027B3197955';
    const usdxAddress = '0xB62D20f527490D78837c8656f6a28331D7723b34';
    const usdcAddress = '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d';
    const ownerAddress = '0x6207dD58F450ab3BC4336be4E5c1cf9a686C30f0';


    const getBnbPrice = async () => {
        // Call the getAmountsOut function to get the price of your token in BNB
        const router = new Contract(routerAddress, pancakeSwapRouterABI, provider);
        router.getAmountsOut(parseUnits('1', 18), [bnbAddress, usdtAddress])
        .then((result) => {
          const bnbPriceInUSDT = formatUnits(result[1], 18);
          setBnbPrice(bnbPriceInUSDT);
          // alert(bnbPriceInUSDT);
        })
        .catch((error) => {
          console.error('Error fetching token price:', error);
        });
    }



    useEffect(() => {
      getBnbPrice();
    }, );


    const prePayment = async () => {
        // Check Wallet Connected
        if (!isConnected){
          open();
          return false;
        }

        // Check Chain ID
        if(chainId != 56){
          switchNetwork(56);
          return false;
        }

        // await getBnbPrice();

        return true;
    }


    // Pay Buttons
  const bnbClick = async () => {
    if(!prePayment()){
      return;
    }
    //prePayment();
    if (!isConnected){
      return;
    }
    const bnbAmount = ((Number(price) / Number(bnbPrice)) + 0.0005).toFixed(5);
    console.log('Price: ' + price);
    console.log('BNB price: ' + bnbPrice);
    // alert(bnbAmount);
    try{


      if(isConnected){
        const provider = new BrowserProvider(walletProvider);
        // const provider = await detectEthereumProvider();
        const signer = await provider.getSigner();
        // console.log(paymentContract);
        const contract = new Contract(paymentContract, abi, signer);

        // Get BNB balance
        const balance = await provider.getBalance(address);
        console.log('Balance: ' + balance);
        console.log('BNB Amount: ' + bnbAmount);
        if(Number(balance) < parseUnits(bnbAmount, 18)){
          alert('Not enough BNB balance in your wallet');
          return;
        };

        console.log("Intialize payment");
        // console.log(signer);
        let getadrp;

        getadrp = await contract.payBnb(fee, ownerAddress, {value: parseUnits(bnbAmount, 18)});

        if(getadrp){
          window.location.href = downloadLink;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

  const usdtClick = async () => {
    if(!prePayment()){
      return;
    }
    if (!isConnected){
      return;
    }
    try{


      if(isConnected){
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const contract = new Contract(paymentContract, abi, signer);
        const usdtContractApproval = new Contract(usdtAddress, ERC20ABI, signer);

        // Get the USDT balance
        const usdtBal = new Contract(usdtAddress, ERC20ABI, provider);
        const balance = await usdtBal.balanceOf(address);
        if(formatUnits(balance, 18) < parseInt(price,10)){
          alert('Not enough USDT balance in your wallet');
          return;
        };

        // Approve USDT Transaction
        const approvalTx = await usdtContractApproval.approve(paymentContract, parseUnits(price, 18));
        await approvalTx.wait();
        console.log('Approval confirmed');

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payUSDT(parseUnits(price, 18), ownerAddress, {value: fee});

        if(getadrp){
          window.location.href = downloadLink;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

  const usdxClick = async () => {
    if(!prePayment()){
      return;
    }
    if (!isConnected){
      return;
    }
    try{


      if(isConnected){
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const contract = new Contract(paymentContract, abi, signer);
        const usdxContractApproval = new Contract(usdxAddress, ERC20ABI, signer);

        // Get the USDX balance
        const usdtBal = new Contract(usdxAddress, ERC20ABI, provider);
        const balance = await usdtBal.balanceOf(address);
        if(formatUnits(balance, 18) < parseInt(price,10)){
          alert('Not enough USDX balance in your wallet');
          return;
        };

        // Approve USDX Transaction
        const approvalTx = await usdxContractApproval.approve(paymentContract, parseUnits(price, 18));
        await approvalTx.wait();
        console.log('Approval confirmed');

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payUSDX(parseUnits(price, 18), ownerAddress, {value: fee});

        if(getadrp){
          window.location.href = downloadLink;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

  const usdcClick = async () => {
    if(!prePayment()){
      return;
    }
    if (!isConnected){
      return;
    }
    try{


      if(isConnected){
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const contract = new Contract(paymentContract, abi, signer);
        const usdcContractApproval = new Contract(usdcAddress, ERC20ABI, signer);

        // Get the USDC balance
        const usdtBal = new Contract(usdcAddress, ERC20ABI, provider);
        const balance = await usdtBal.balanceOf(address);
        if(formatUnits(balance, 18) < parseInt(price,10)){
          alert('Not enough USDC balance in your wallet');
          return;
        };

        // Approve USDC Transaction
        const approvalTx = await usdcContractApproval.approve(paymentContract, parseUnits(price, 18));
        await approvalTx.wait();
        console.log('Approval confirmed');

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payUSDC(parseUnits(price, 18), ownerAddress, {value: fee});

        if(getadrp){
          window.location.href = downloadLink;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }


    return (
        <Modal show={show} onHide={handleClose}>

            <Modal.Body>
                <h2 className="text-center" style={{ color: 'gray' }}>Pay : ${price} </h2>
                <br />
                <p style={{ color: 'gray' }}>Select payment coin :</p>

                <Button variant="secondary m-2" onClick={bnbClick}>
                    <img src="images/icons/bnb.png" height={25} width={25} alt="BNB Icon" className="button-icon me-2" />
                    BNB
                </Button>
                <Button variant="secondary m-2" onClick={usdtClick}>
                    <img src="images/icons/usdt.png" height={25} width={25} alt="USDT Icon" className="button-icon me-2" />
                    USDT
                </Button>
                <Button variant="secondary m-2" onClick={usdxClick}>
                  <img src="images/icons/usdx.png" height={25} width={25} alt="USDX Icon" className="button-icon me-2" />
                    USDX
                </Button>
                <Button variant="secondary m-2" onClick={usdcClick}>
                    <img src="images/icons/usdc.png" height={25} width={25} alt="USDC Icon" className="button-icon me-2" />
                    USDC
                </Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            <br />
        </Modal>
    );
};

export default PaymentModal;
