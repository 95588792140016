import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function Zxf() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">Tokens Creator</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={12} xl={12}>
                                <div>
                                Create your own tokens creator website and earn fees, you can set the fees you want for every network, when users create tokens %50 of token creation fee will sent immediately to your wallet.
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h2 style={{color:'yellow'}}>Create Amazing Tokens Creator Platform FREE</h2>
                                    <h2 style={{color:'yellow'}}>💰💰 and Earn Money 💰💰</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                        <img src="images/earnmoney.png" alt="QR Code" />
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h2 style={{color:'yellow'}}><a href="https://bit.ly/tokens-creator" target="_blank" rel="noreferrer">Download Now</a></h2>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                <h3>Learn how to install: </h3>
                                <br/>
                                <div>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/y8rbrsFmlcs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}