import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function Dex() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">Pancake Fork</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={8} xl={8}>
                                <div>
                                    Get your own fully operational decenteralized exchange on binance smart chain powered by Swap Ocean Ecosystem.
                                </div>
                                <div>
                                    Our main goal is to make the decentralized exchanges really decentralized and allow anyone to have his own DEX and all DEXs we create powered by Swap Ocean Ecosystem
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h1>Premium Dex</h1>
                                </div>
                                <br/>
                                <h4>Details : </h4>
                                <div>- Your Token is The Default Swap Token with BNB</div>
                                <div>- PancakeSwap V2 Fork</div>
                                <div>- Based on PancakeSwap Router V2</div>
                                <div>- Support BSC and Ethereum Networks</div>
                                <div>- No Trading Fee</div>
                                <div>- Demo : <a href="https://swapocean.com/" target="_blank" rel="noreferrer">https://swapocean.com</a></div>
                                <br/>
                                <h4>Price : 0.5 BNB</h4>
                                <br/>
                                <div className="text-center">
                                <a href="https://t.me/mohamedmox" target="_blank" rel="noreferrer"><h3 style={{color: 'yellow'}}>Contact Us</h3></a>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <div className="text-center">
                                    <h1>Golden Dex (Earn Trading Fee)</h1>
                                </div>
                                <br/>
                                <div>- Earn Trading Fee</div>
                                <div>- Your Token is The Default Swap Token with BNB</div>
                                <div>- PancakeSwap V2 Fork</div>
                                <div>- Based on PancakeSwap Router V2</div>
                                <div>- Support BSC and Ethereum Networks</div>
                                <div>- Demo : <a href="https://swapocean.com/" target="_blank" rel="noreferrer">https://swapocean.com</a></div>
                                <br/>
                                <h4>Price : 5 BNB</h4>
                                <br/>
                                <b/>
                                <div className="text-center">
                                <a href="https://t.me/mohamedmox" target="_blank" rel="noreferrer"><h3 style={{color: 'yellow'}}>Contact Us</h3></a>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} xl={4}>
                                <Box>
                                    <div className="text-center">
                                        <img src="images/swapocean.png" width={"100%"} alt="Dex" />
                                    </div>
                                    <br/>
                                </Box>
                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}
