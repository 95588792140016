import React from "react";
import { useEffect, useState } from 'react';
import PageLayout from "../../layouts/PageLayout";
import { DivideTitle } from "../../components";
import { Row, Col } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { LabelField } from "../../components/fields";
import { Box, Button } from "../../components/elements";
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, addDoc} from "firebase/firestore";

export default function EasyBuy() {

    const contractAddress = "0x6499157c0bfFD8987F0AcFda3A85636d6E0DECce";
    const abi = [{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address","name":"_tokenAddress","type":"address"}],"name":"createEasyBuy","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"name":"getNewEasyBuy","outputs":[{"internalType":"contract EasyBuy","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"newEasyBuy","outputs":[{"internalType":"contract EasyBuy","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address payable","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"}];
    const fee = "5000000000000000";

    let myNewContract = "";
    let tempNewContract = "";
    const navigate = useNavigate();

    // 4. Use modal hook
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

    const [ContractText, setContractText] = useState(null);
    const [titleText, setTitleText] = useState("Easy Buy Token");
    const [descText, setDescText] = useState("Amazing Token");
    const [logoText, setLogoText] = useState("https://0xfactory.com/logo.png");
    const [twitterText, setTwitterText] = useState("https://twitter.com");
    const [facebookText, setFacebookText] = useState("https://www.facebook.com");
    const [telegramText, setTelegramText] = useState("https://t.me");
    const [isLoading, setIsLoading] = useState(false);
    const [createButtonText, setCreateButtonText] = useState("Create Contract")
    const [createButtonIcon, setCreateButtonIcon] = useState("add")
    const [createButtonColor, setCreateButtonColor] = useState("mc-btn green m-2")



      const handleContractChange = (event) => {
        setContractText(event.target.value);
      };
      const handleTitleChange = (event) => {
        setTitleText(event.target.value);
      };
      const handleDescChange = (event) => {
        setDescText(event.target.value);
      };
      const handleLogoChange = (event) => {
        setLogoText(event.target.value);
      };
      const handleTwitterChange = (event) => {
        setTwitterText(event.target.value);
      };
      const handleFacebookChange = (event) => {
        setFacebookText(event.target.value);
      };
      const handleTelegramChange = (event) => {
        setTelegramText(event.target.value);
      };

      // Add new contract to firebase
      const collectionRef = collection(db, 'EasyBuy');
      const addContract = async (newContract) => {
        await addDoc(collectionRef,{contract: newContract, title: titleText, description: descText, logo: logoText, token: ContractText, twitter: twitterText, facebook: facebookText, telegram: telegramText});
      }




      const connectWalletButton = () => {
        return (
          <Button onClick={() => open()} icon="wallet" text="Wallet Connect" className="mc-btn primary m-2" />
        )
      }

      const createButton = () => {
        return (
          <Button onClick={createNewContract} icon={createButtonIcon} text={createButtonText} className={createButtonColor} disabled={isLoading} />
        )
      }


      useEffect(() => {

      }, [])

      function timeout() {
        return new Promise( res => setTimeout(res, 5000) );
      }

      const createNewContract = async() => {
        try{
          

          if(isConnected){
            const provider = new BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            const contract = new Contract(contractAddress, abi, signer);

            console.log("Intialize payment");
            let newEasyBuy = await contract.createEasyBuy(ContractText, {value: fee});
            tempNewContract = await contract.getNewEasyBuy();
            myNewContract = tempNewContract;

            setIsLoading(true);
            setCreateButtonText("Please Wait...");
            setCreateButtonIcon("timer");
            setCreateButtonColor("mc-btn red m-2");

            while(tempNewContract === myNewContract){
              myNewContract = await contract.getNewEasyBuy();
              await timeout();
            }
        if(newEasyBuy){
          addContract(myNewContract);
          await timeout();
          navigate(`/easy-buy-contract?id=${myNewContract}&chain=bsc`);
          // alert("Congratulations, your ico launchpad has been created on smart chain testnet.");
          // alert(myNewContract);
          //  console.log(myNewContract);
        }else{
          alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
        }
          }
        }catch(err){
          alert(err);
          //console.log(err);
        }

      }

    return (
        <PageLayout>
            <Row>
            <Col xl={12}>
                    <Box className="mc-card">
                        <CardHeader title="Create Easy Buy Contract" />
                        <div>This contract will buy the token automatically when receive BNB and send it to the BNB sender wallet.</div>
                        <div>Working only for token on binance smart chain which have liquidity on pancake swap with BNB.</div>
                        <Row xs={12} md={12} xl={12}>
                            <Col>
                                <DivideTitle title=" " className="mb-4 mt-3" />
                                <LabelField onChange={handleContractChange} value={ContractText} label="Token Contract" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a.............." />
                                <LabelField onChange={handleTitleChange} value={titleText} label="Title" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="Easy Buy ZXF Token" />
                                <LabelField onChange={handleDescChange} value={descText} label="Description" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="ZXF is the native token that powers the thriving ecosystem of 0xFactory......" />
                                <LabelField onChange={handleLogoChange} value={logoText} label="Logo URL" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="https://0xfactory.com/logo.png" />
                                <LabelField onChange={handleTwitterChange} value={twitterText} label="Twitter" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="https://twitter.com/0xFactoryCom" />
                                <LabelField onChange={handleFacebookChange} value={facebookText} label="Facebook" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="https://www.facebook.com/0xFactory" />
                                <LabelField onChange={handleTelegramChange} value={telegramText} label="Telegram" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="https://t.me/ZeroxFactory" />
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xl={12}>
                    <Box className="mb-4">
                        <Row xs={12} md={3} xl={3} className="justify-content-center">
                          {isConnected ? createButton() : connectWalletButton()}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}
