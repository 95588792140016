import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function Cex() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">Free Listing on 0xTrader.com</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={8} xl={8}>
                                <div>
                                    At 0xTrader.com, we believe in fostering a vibrant and diverse crypto ecosystem. To support this vision, we are excited to offer a Free Listing Program for innovative and promising crypto projects. This program is designed to provide emerging tokens with the opportunity to gain exposure and liquidity without the burden of listing fees.
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h1>We Support BSC Tokens Only</h1>
                                </div>
                                <br/>
                                <h3>Key Benefits: : </h3>
                                <br/>
                                <div><h5>1- Zero Listing Fees:</h5> List your token on 0xTrader.com at no cost, allowing you to allocate resources to further development and community engagement.</div>
                                <br/>
                                <div><h5>2- Increased Visibility:</h5> Gain access to our extensive user base, providing your project with heightened visibility and potential trading volume.</div>
                                <br/>
                                <div><h5>3- Liquidity Access:</h5> Enhance the liquidity of your token by leveraging our advanced trading infrastructure and active trading community.</div>
                                <br/>
                                <div><h5>4- Marketing Support:</h5> Benefit from promotional support through our platform's channels, including social media, newsletters, and featured listings.</div>
                                <br/>
                                <div><h5>5- Community Engagement:</h5> Engage with a global community of crypto enthusiasts and investors who are always on the lookout for new and innovative projects.</div>
                                <br/>
                                <div><h4>Create Account Now : <a href="https://0xtrader.com/register" target="_blank" rel="noreferrer">https://0xtrader.com</a></h4></div>
                                <br/>
                                <br/>
                                <div className="text-center">
                                <a href="https://t.me/mohamedmox" target="_blank" rel="noreferrer"><h3 style={{color: 'yellow'}}>Contact Us For Listing</h3></a>
                                </div>
                                <br/>
                                
                            </Col>
                            <Col xs={12} sm={4} xl={4}>
                                <Box>
                                    <div className="text-center">
                                        <img src="images/listing.png" width={"100%"} alt="Dex" />
                                    </div>
                                    <br/>
                                </Box>
                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}