// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB5objpufsTMwkepjMcNXwy3nHhuJPf3BA",
  authDomain: "zxfactorycom.firebaseapp.com",
  projectId: "zxfactorycom",
  storageBucket: "zxfactorycom.appspot.com",
  messagingSenderId: "820193403069",
  appId: "1:820193403069:web:f318fad6a95a839c0017d4",
  measurementId: "G-FEH0SBMCZJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);