import React from "react";
import { useEffect, useState } from 'react';
import PageLayout from "../../layouts/PageLayout";
import { DivideTitle } from "../../components";
import { Row, Col } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { LabelField } from "../../components/fields";
import { Box, Button } from "../../components/elements";
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';
import detectEthereumProvider from '@metamask/detect-provider';
import { useNavigate } from "react-router-dom";


export default function AirdropAff() {

    //const contractAddress = "0x37906a8474179265b95750e1Ca10c1df0c03731E";
    const abi = [{"inputs":[{"internalType":"address","name":"_seller","type":"address"},{"internalType":"address","name":"tokenContract","type":"address"},{"internalType":"uint256","name":"airdropAmount","type":"uint256"},{"internalType":"uint256","name":"fees","type":"uint256"},{"internalType":"uint256","name":"decimals","type":"uint256"},{"internalType":"uint256","name":"_commission","type":"uint256"}],"name":"createAirdrop","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[],"name":"getNewAirdrop","outputs":[{"internalType":"contract Airdrop","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"newAirdrop","outputs":[{"internalType":"contract Airdrop","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address payable","name":"","type":"address"}],"stateMutability":"view","type":"function"}];
    // const fee = "30000000000000000";

    let myAirdropContract = "";
    let tempAirdropContract = "";
    const navigate = useNavigate();

    // 4. Use modal hook
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

    const [amountText, setamountText] = useState("100");
    const [commissionText, setCommissionText] = useState("10");
    const [feeText, setfeeText] = useState("69000000000000000");
    const [claimFee, setClaimFee] = useState("0.001");
    const [decimalText, setdecimalText] = useState("18");
    const [ownerText, setOwnerText] = useState(null);
    const [contractText, setContractText] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSupportedNetwork, setIsSupportedNetwork] = useState(true);
    const [contractAddress, setContractAddress] = useState("0x9738F064d464F4cd74e35EF9d56A5A081CD93Bbd");
    const [createButtonText, setCreateButtonText] = useState("Create Airdrop")
    const [createButtonIcon, setCreateButtonIcon] = useState("add")
    const [createButtonColor, setCreateButtonColor] = useState("mc-btn green m-2")
    //const [singleClaim, setSingleClaim] = useState(true);
    const [tokenSymbol, setTokenSymbol] = useState(null);


      const handleAmountChange = (event) => {
        setamountText(event.target.value);
      };

      const handleCommissionChange = (event) => {
        setCommissionText(event.target.value);
      };

      const handleSymbolChange = (event) => {
        setTokenSymbol(event.target.value);
      };

      const handleFeeChange = (event) => {
        setClaimFee(event.target.value);
      };

      const handleDecimalChange = (event) => {
        setdecimalText(event.target.value);
      };

      const handleOwnerChange = (event) => {
        setOwnerText(event.target.value);
      };

      const handleContractChange = (event) => {
        setContractText(event.target.value);
      };



      const connectWalletButton = () => {
        return (
          <Button onClick={() => open()} icon="wallet" text="Wallet Connect" className="mc-btn primary m-2" />
        )
      }

      const createButton = () => {
        return (
          <Button onClick={createAirdrop} icon={createButtonIcon} text={createButtonText} className={createButtonColor} disabled={isLoading} />
        )
      }

      const checkNetwork = async () =>{

        switch (chainId) {
            // case 1:
            //   setContractAddress("0x579ff00B58bA8faf43fAB8D7775fC3b2Ad37f84f"); // compiler 8.25
            //   setfeeText("10000000000000000");
            //   setIsSupportedNetwork(true);
            //   break;
            case 56:
              setContractAddress("0x9738F064d464F4cd74e35EF9d56A5A081CD93Bbd"); // compiler 8.25
              setfeeText("69000000000000000");
              setIsSupportedNetwork(true);
              break;
            // case 8453:
            //   setContractAddress("0x09417638A4376e9DCec746588d78BcFa62B0cB94"); // Base compiler 8.25
            //   setfeeText("10000000000000000");
            //   setIsSupportedNetwork(true);
            //   break;
            // case 42161:
            //   setContractAddress("0x6aD96720Bc1B1028363EFa75160C344007deff11"); // Arb compiler 8.25
            //   setfeeText("10000000000000000");
            //   setIsSupportedNetwork(true);
            //   break;
            // case 137:
            //     setContractAddress("0x74775eAb03E010a5024408e6a4989aDd56e01183"); // compiler 8.19
            //     setfeeText("20000000000000000000");
            //     setIsSupportedNetwork(true);
            //   break;
            // case 43114:
            //     setContractAddress("0xF2489780Da67a80ae30B2be1cD216E4033920AEe");
            //     setfeeText("1000000000000000000");
            //     setIsSupportedNetwork(true);
            //   break;
            //   case 250:
            //   setContractAddress("0x74775eAb03E010a5024408e6a4989aDd56e01183"); // compiler 8.19
            //   setfeeText("30000000000000000000");
            //   setIsSupportedNetwork(true);
            //   break;
            // case 13600:
            //     setContractAddress("0x6aD96720Bc1B1028363EFa75160C344007deff11"); // compiler 8.19
            //     setfeeText("10000000000000000000000");
            //     setIsSupportedNetwork(true);
            //   break;
            // case 1116: // Core Network
            //     setContractAddress("0x509c97d4A7b98C91f1264304f228e3639416F118");
            //     setfeeText("5000000000000000000");
            //     setIsSupportedNetwork(true);
            //   break;
            // case 97:
            //     setContractAddress("0x74775eAb03E010a5024408e6a4989aDd56e01183"); // compiler 8.19
            //     setfeeText("50000000000000000");
            //     setIsSupportedNetwork(true);
            //   break;
            // case 105105: // Startis EVM
            //     setContractAddress("0xa63dAF9bb0756C4909fB924d90BBb1Ee949A75b2"); // compiler 8.24
            //     setfeeText("100000000000000000000");
            //     setIsSupportedNetwork(true);
            //   break;
            // case 47805: // REI Network
            //     setContractAddress("0x6aD96720Bc1B1028363EFa75160C344007deff11"); // compiler 8.24
            //     setfeeText("100000000000000000000");
            //     setIsSupportedNetwork(true);
            //   break;
            default:
              setIsSupportedNetwork(false);
              break;
          }
      }

      useEffect(() => {
        // checkNetwork();
      }, [])

      function timeout() {
        return new Promise( res => setTimeout(res, 5000) );
      }

      const createAirdrop = async() => {
        await checkNetwork();
        if(!isSupportedNetwork){
            alert("This network not supported");
            return;
        }
        try{

          let newFeeText = String(parseFloat(claimFee) * 1000000000000000000);
          // console.log(newFeeText);
          // console.log(amountText);
          if(isConnected){
            const provider = new BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            const contract = new Contract(contractAddress, abi, signer);

            const bnbBalance = await provider.getBalance(address);
            if(bnbBalance <= Number(feeText)){
              alert("Not enough balance.");
              return;
            }

            console.log("Intialize payment");
            // alert(ownerText + contractText + amountText + newFeeText + decimalText);
            let newAirdrop = await contract.createAirdrop(ownerText, contractText, amountText, newFeeText, decimalText, commissionText, {value: feeText});
            tempAirdropContract = await contract.getNewAirdrop();
            myAirdropContract = tempAirdropContract;

            setIsLoading(true);
            setCreateButtonText("Please Wait...");
            setCreateButtonIcon("timer");
            setCreateButtonColor("mc-btn red m-2");

            while(tempAirdropContract === myAirdropContract){
              myAirdropContract = await contract.getNewAirdrop();
              await timeout();
            }
        if(newAirdrop){
          navigate(`/contract-airdrop?id=${myAirdropContract}`);
          // alert("Congratulations, your ico launchpad has been created on smart chain testnet.");
          // alert(myAirdropContract);
          //  console.log(myAirdropContract);
        }else{
          alert("Something wrong, Some fields maybe missing.");
        }
          }
        }catch(err){
        alert("Something wrong, try again.");
          console.log(err);
        }

      }

    return (
        <PageLayout>
            <Row>
            <Col xl={12}>
                    <Box className="mc-card">
                        <CardHeader title="Create Airdrop With Affiliate" />
                        <p>Supported Blockchains: BNB Smart Chain</p>
                        <p>Creation Fee: 0.07 BNB</p>
                        <p>Demo: <a href="https://airdrop2.0xfactory.com/" target="blank">airdrop2.0xfactory.com</a></p>
                        <p>Recommended Hosting: <a href="https://bit.ly/3PZsE4A" target="blank">Click Here and Get %77 OFF</a></p>
                        <Row xs={12} md={12} xl={12}>
                            <Col>
                                <DivideTitle title=" " className="mb-4 mt-3" />
                                <LabelField onChange={handleOwnerChange} value={ownerText} label="Owner Wallet" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a......." />
                                <LabelField onChange={handleContractChange} value={contractText} label="Token Contract" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a......." />
                                <LabelField onChange={handleSymbolChange} value={tokenSymbol} label="Token Symbol" type="text" min="0" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="BTC" />
                                <LabelField onChange={handleDecimalChange} value={decimalText} label="Your token decimal" type="text" min="0" max="18" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="18" />
                                <LabelField onChange={handleAmountChange} value={amountText} label="Airdrop Amount" type="text" min="1" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="100" />
                                <LabelField onChange={handleCommissionChange} value={commissionText} label="Commission Amount" type="text" min="1" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="10" />
                                <LabelField onChange={handleFeeChange} value={claimFee} label="Fee in (ETH, BNB, MATIC, AVAX, FTM, CORE or KNB)" type="text" min="0" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0.001" />
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xl={12}>
                    <Box className="mb-4">
                        <Row xs={12} md={3} xl={3} className="justify-content-center">
                          {isConnected ? createButton() : connectWalletButton()}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}
