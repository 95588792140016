import React from "react";
import { useEffect, useState } from 'react';
import PageLayout from "../../layouts/PageLayout";
import { DivideTitle } from "../../components";
import { Row, Col } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { LabelField } from "../../components/fields";
import { Box, Button } from "../../components/elements";
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';
import detectEthereumProvider from '@metamask/detect-provider';
import { useNavigate } from "react-router-dom";

export default function CreateLaunchpad() {

    // const contractAddress = "0x645073957f1a12f239Ab81795Ba594b64CD8DB95";
    const abi = [{"inputs":[{"internalType":"address","name":"_seller","type":"address"},{"internalType":"address","name":"_tokenAddress","type":"address"},{"internalType":"uint256","name":"_rate","type":"uint256"},{"internalType":"uint256","name":"_tokenDecimal","type":"uint256"},{"internalType":"uint256","name":"_commission","type":"uint256"}],"name":"createPresale","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[],"name":"getNewPresale","outputs":[{"internalType":"contract Presale","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"newPresale","outputs":[{"internalType":"contract Presale","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address payable","name":"","type":"address"}],"stateMutability":"view","type":"function"}];
    // let fee = "50000000000000000";

    let myPresaleContract = "";
    let tempPresaleContract = "";
    const navigate = useNavigate();

    // 4. Use modal hook
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

    const [rateText, setrateText] = useState("1000");
    const [decimalText, setdecimalText] = useState("18");
    const [affiliateCommission, setAffiliateCommission] = useState("7");
    const [ownerText, setOwnerText] = useState(null);
    const [contractText, setContractText] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [createButtonText, setCreateButtonText] = useState("Create Launchpad")
    const [createButtonIcon, setCreateButtonIcon] = useState("add")
    const [createButtonColor, setCreateButtonColor] = useState("mc-btn green m-2")
    const [contractAddress, setContractAddress] = useState("0x74775eAb03E010a5024408e6a4989aDd56e01183");
    const [isSupportedNetwork, setIsSupportedNetwork] = useState(true);
    const [feeText, setfeeText] = useState("51000000000000000");


      const handleRateChange = (event) => {
        setrateText(event.target.value);
      };

      const handleDecimalChange = (event) => {
        setdecimalText(event.target.value);
      };

      const handleCommissionChange = (event) => {
        setAffiliateCommission(event.target.value);
      };

      const handleOwnerChange = (event) => {
        setOwnerText(event.target.value);
      };

      const handleContractChange = (event) => {
        setContractText(event.target.value);
      };



      const connectWalletButton = () => {
        return (
          <Button onClick={() => open()} icon="wallet" text="Wallet Connect" className="mc-btn primary m-2" />
        )
      }

      const createButton = () => {
        return (
          <Button onClick={createPresale} icon={createButtonIcon} text={createButtonText} className={createButtonColor} disabled={isLoading} />
        )
      }

      const checkNetwork = async () =>{
        switch (chainId) {
            case 1:
              setContractAddress("0x678e1854F77BB324187c38f2630BDF988B8568C9"); // compiler 8.25
              setfeeText("10000000000000000");
              setIsSupportedNetwork(true);
              break;
            case 56:
              setContractAddress("0x74775eAb03E010a5024408e6a4989aDd56e01183"); // compiler 8.19
              setfeeText("51000000000000000");
              setIsSupportedNetwork(true);
              break;
            case 8453:
              setContractAddress("0xa63dAF9bb0756C4909fB924d90BBb1Ee949A75b2"); // Base compiler 8.25
              setfeeText("10000000000000000");
              setIsSupportedNetwork(true);
              break;
            case 42161:
              setContractAddress("0x37906a8474179265b95750e1Ca10c1df0c03731E"); // Arb compiler 8.25
              setfeeText("10000000000000000");
              setIsSupportedNetwork(true);
              break;
            case 137:
                setContractAddress("0x37906a8474179265b95750e1Ca10c1df0c03731E"); // Polygon compiler 8.19
                setfeeText("30000000000000000000");
                setIsSupportedNetwork(true);
              break;
            // case '43114':
                // setContractAddress("0xF2489780Da67a80ae30B2be1cD216E4033920AEe"); // Avalanche Need contract
                //setfeeText("1000000000000000000");
                // setIsSupportedNetwork(true);
              // break;
              case 250:
              setContractAddress("0x37906a8474179265b95750e1Ca10c1df0c03731E"); // Fantom compiler 8.19
              setfeeText("30000000000000000000");
              setIsSupportedNetwork(true);
              break;
            case 1116:
                setContractAddress("0xBC227b7E290549c77Cf5e89B983511FFF6338A2c"); // Core compiler 8.19
                setfeeText("15000000000000000000");
                setIsSupportedNetwork(true);
              break;
            case 13600:
                setContractAddress("0x37906a8474179265b95750e1Ca10c1df0c03731E"); // Kronobit compiler 8.19
                setfeeText("10000000000000000000000");
                setIsSupportedNetwork(true);
              break;
            case 97:
                setContractAddress("0x37906a8474179265b95750e1Ca10c1df0c03731E"); // BSC Testnet compiler 8.19
                setfeeText("50000000000000000");
                setIsSupportedNetwork(true);
              break;
            case 105105:
                setContractAddress("0x6aD96720Bc1B1028363EFa75160C344007deff11"); // Stratis compiler 8.24
                setfeeText("100000000000000000000");
                setIsSupportedNetwork(true);
              break;
            case 47805:
                setContractAddress("0x6aD96720Bc1B1028363EFa75160C344007deff11"); // REI compiler 8.24
                setfeeText("100000000000000000000");
                setIsSupportedNetwork(true);
              break;
            default:
              setIsSupportedNetwork(false);
              break;
          }
      }

      useEffect(() => {
        // checkNetwork();
      }, [])

      function timeout() {
        return new Promise( res => setTimeout(res, 5000) );
      }

      const createPresale = async() => {
        await checkNetwork();
        if(!isSupportedNetwork){
            alert("This network not supported");
            return;
        }

        try{
          if(isConnected){
            const provider = new BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            const contract = new Contract(contractAddress, abi, signer);

            const bnbBalance = await provider.getBalance(address);
            if(bnbBalance <= Number(feeText)){
              alert("Not enough balance.");
              return;
            }

            console.log("Intialize payment");
            let newPresale = await contract.createPresale(ownerText, contractText, rateText, decimalText, affiliateCommission, {value: feeText});
            tempPresaleContract = await contract.getNewPresale();
            myPresaleContract = tempPresaleContract;

            setIsLoading(true);
            setCreateButtonText("Please Wait...");
            setCreateButtonIcon("timer");
            setCreateButtonColor("mc-btn red m-2");

            while(tempPresaleContract === myPresaleContract){
              myPresaleContract = await contract.getNewPresale();
              await timeout();
            }
        if(newPresale){
          navigate(`/contract-launchpad?id=${myPresaleContract}`);
        }else{
          alert("Something wrong, Some fields maybe missing.");
        }
          }
        }catch(err){
        alert("Something wrong, try again.");
          console.log(err);
        }

      }

    return (
        <PageLayout>
            <Row>
            <Col xl={12}>
                    <Box className="mc-card">
                        <CardHeader title="Create Launchpad Contract" />
                        <p>Supported Blockchains (ETH, BSC, Base, Arbitrum One, Polygon, Avalanche, Fantom, Core, Stratis and BSC Testnet)</p>
                        <p>Creation Fee: 0.05 BNB, 0.01 ETH, 20 MATIC, 30 FTM ....</p>
                        <p>Recommended Hosting: <a href="https://bit.ly/3PZsE4A" target="blank">Click Here and Get %77 OFF</a></p>
                        <Row xs={12} md={12} xl={12}>
                            <Col>
                                <DivideTitle title=" " className="mb-4 mt-3" />
                                <LabelField onChange={handleOwnerChange} value={ownerText} label="Owner Wallet" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a......." />
                                <LabelField onChange={handleContractChange} value={contractText} label="Token Contract" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="0xAb8a......." />
                                <LabelField onChange={handleRateChange} value={rateText} label="Rate (1 Native Coin = ???? of your token), Example: 1 BNB = 1000 TOKEN" type="text" min="1" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="1000" />
                                <LabelField onChange={handleDecimalChange} value={decimalText} label="Your token decimal" type="text" min="0" max="18" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="18" />
                                <LabelField onChange={handleCommissionChange} value={affiliateCommission} label="Affiliate Commission" type="text" min="0" fieldSize="w-100 h-lg" labelDir="label-col mb-4" placeholder="7" />
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xl={12}>
                    <Box className="mb-4">
                        <Row xs={12} md={3} xl={3} className="justify-content-center">
                          {isConnected ? createButton() : connectWalletButton()}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}
