import React from "react";
import { Image, List, Box, Text, Item, Anchor, Button } from "../../components/elements";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";

export default function TokensContracts() {
    return (
        <PageLayout>
            <CardLayout className="mb-4">
                <Breadcrumb title="Download Token Contracts"></Breadcrumb>
            </CardLayout>

            <CardLayout>
                <List className="mc-notify-list">
                    
                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/bep20.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">BEP20 Token Contract ( FREE)</Text>
                                    <Text as="small">Create an BEP20 Token with collecting tax in USDX fee on binance smart chain network in less than a minute with our full audited smart contract solidity code, 100% token ownership.</Text>
                                </Box>
                            <Anchor href="https://bit.ly/BEP20">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/eth.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">ERC20 Token Contract ( Price: $5 )</Text>
                                    <Text as="small">Create an ERC20 Token with collecting tax in ETH fee on ethereum network in less than a minute with our full audited smart contract solidity code, 100% token ownership.</Text>
                                </Box>
                            <Anchor href="https://pay.0xfactory.com/pay.php?checkout_id=2">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/base.svg" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Base Token Contract ( Price: $5 )</Text>
                                    <Text as="small">Create an ERC20 Token with collecting tax in ETH fee on base network in less than a minute with our full audited smart contract solidity code, 100% token ownership.</Text>
                                </Box>
                            <Anchor href="https://pay.0xfactory.com/pay.php?checkout_id=8">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/poly.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Polygon Token Contract ( Price: $5 )</Text>
                                    <Text as="small">Create an ERC20 Token with collecting tax in MATIC fee on polygon network in less than a minute with our full audited smart contract solidity code, 100% token ownership.</Text>
                                </Box>
                            <Anchor href="https://pay.0xfactory.com/pay.php?checkout_id=3">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/avax.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Avalanche Token Contract ( Price: $5 )</Text>
                                    <Text as="small">Create an ERC20 Token with collecting tax in AVAX fee on avalanche network in less than a minute with our full audited smart contract solidity code, 100% token ownership.</Text>
                                </Box>
                            <Anchor href="https://pay.0xfactory.com/pay.php?checkout_id=4">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                        </Item>        

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/ftm.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Fantom Token Contract ( Price: $5 )</Text>
                                    <Text as="small">Create an ERC20 Token with collecting tax in FTM fee on fantom network in less than a minute with our full audited smart contract solidity code, 100% token ownership.</Text>
                                </Box>
                            <Anchor href="https://pay.0xfactory.com/pay.php?checkout_id=5">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                        </Item>     

                         <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/token.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Standard Token Contract ( Price: $5 )</Text>
                                    <Text as="small">Create standrad token, you can delpoy on any network in less than a minute with our full audited smart contract solidity code, 100% token ownership.</Text>
                                </Box>
                            <Anchor href="https://pay.0xfactory.com/pay.php?checkout_id=6">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                        </Item>      

                        <Item className="mc-notify-item" key="token">
                                <Box className="mc-notify-media">
                                    <Image src="images/burn.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Burn Token Contract ( Price: $5 )</Text>
                                    <Text as="small">Create a token that collect tax and burn it, you can delpoy on any network in less than a minute with our full audited smart contract solidity code, 100% token ownership.</Text>
                                </Box>
                            <Anchor href="https://pay.0xfactory.com/pay.php?checkout_id=7">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                        </Item>    
                    
                </List>
            </CardLayout>
        </PageLayout>
    )
}