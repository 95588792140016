import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box } from "../../components/elements";
import { useSearchParams } from "react-router-dom";

export default function Contract() {

    const [searchParams] = useSearchParams();


    return (
        <PageLayout>
            <Box className="mc-blank">
                <h1>Congratulations</h1>
                <h2>Your launchpad contract has been created</h2>
                <br/>
                <h3>{searchParams.get('id')}</h3>
                <br/>
                <br/>
                <br/>
                Next step : send your presale tokens to the above presale contract and download the Launchpad UI app.
                <br/>
                <h3><a href="https://bit.ly/launchpad-ui-plus" target="_blank" rel="noreferrer">Download Launchpad UI Plus</a></h3>
                <br/>
                <h3><a href="https://0xfactory.com/download/Launchpad-UI.zip" target="_blank" rel="noreferrer">Download Launchpad UI</a></h3>
            </Box>
        </PageLayout>
    )
}