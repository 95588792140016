import React , { useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { Anchor, Box, Image, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";
import data from "../../data/supports/overview.json";
import PaymentModal from '../../PaymentModal';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';


export default function Downloads() {

  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

  const [showModal, setShowModal] = useState(false);
  const [priceModal, setPriceModal] = useState(null);
  const [downloadLinkModal, setDownloadLinkModal] = useState(null);

  const handleShow = (link, price) => {
    if(isConnected){
      setPriceModal(price);
      setDownloadLinkModal(link);
      setShowModal(true);
    }else{
      open();
    }
  }
  const handleClose = () => setShowModal(false);


    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">Downloads</Heading>
                <div className="text-center">
                    <h3>Download Decentralized Applications.</h3>
                </div>
                <br/>
                <Row xs={1} sm={2} xl={3}>
                    {data?.premiumApps.map((item, index) => (
                        <Col key={index} >
                            <Box className="mc-overview-card">
                                <Heading className="mc-overview-name">{item.title}</Heading>
                                <Box className="mc-overview-media">
                                    <Image src={item.image} alt="demo" />
                                    <h3>Price: ${item.price}</h3>
                                    <p>
                                        {item.description}
                                    </p>
                                    <Box className="mc-overview-overlay">
                                        <Anchor onClick={() => handleShow(item.path, item.price)} rel="noopener noreferrer" className="mc-overview-link">Download</Anchor>
                                    </Box>
                                </Box>
                            </Box>
                            <center><a href={item.demo} rel="noopener noreferrer" target="_blank"><h3 style={{color: 'orange'}}>Live Demo</h3></a></center>

                        </Col>
                    ))}
                </Row>

                <br/>
                <br/>
                <br/>
                <br/>
                <div className="text-center">
                    <h3>Download Solidity Smart Contracts Code.</h3>
                </div>
                <br/>
                <Row xs={1} sm={2} xl={3}>
                    {data?.contracts.map((item, index) => (
                        <Col key={index} >
                            <Box className="mc-overview-card">
                                <Heading className="mc-overview-name">{item.title}</Heading>
                                <Box className="mc-overview-media">
                                    <Image src={item.image} alt="demo" />
                                    <h3>Price: ${item.price}</h3>
                                    <p>
                                        {item.description}
                                    </p>
                                    <Box className="mc-overview-overlay">
                                        <Anchor onClick={() => handleShow(item.path, item.price)} rel="noopener noreferrer" className="mc-overview-link">Download</Anchor>
                                    </Box>
                                </Box>
                            </Box>
                        </Col>
                    ))}
                </Row>
                <PaymentModal show={showModal} handleClose={handleClose} downloadLink={downloadLinkModal} price={priceModal} />
                </Container>
        </PageLayout>
    )
}
