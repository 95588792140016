import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box } from "../../components/elements";
import { useSearchParams } from "react-router-dom";

export default function EasyBuyContract() {

    const [searchParams] = useSearchParams();

    let buyLink = "https://buy.0xfactory.com/";

    return (
        <PageLayout>
            <Box className="mc-blank">
                <h1>Congratulations</h1>
                <h2>Your Easy Buy contract has been created</h2>
                <br/>
                <a href={buyLink + searchParams.get('id')} target="_blank" rel="noreferrer"><h3>{searchParams.get('id')}</h3></a>
                <br/>
                <br/>
                <br/>
                <br/>
                <h4>Easy Buy Link : <a href={buyLink + searchParams.get('id')} target="_blank" rel="noreferrer">{buyLink + searchParams.get('id')}</a></h4>
                <br/>
            </Box>
        </PageLayout>
    )
}