import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function Tut() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">Video Tutorials</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={12} xl={12}>
                                <div>
                                Video tutorials for blockchain-related topics like presales, airdrops, decentralized applications (DApps), and token creation should be informative, engaging, and easy to follow. 
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h2>1- Create ICO Launchpad Website with Affiliate</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Dhsq52HFYr8?si=-cfYaHr5EuVYCbkN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                
                                <br/>
                                <br/>
                                <div className="text-center">
                                    <h2>2- Create Token Airdrop Website</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/m92ARWf1o1g?si=aX-u-EXbmW5RNBoR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>

                                <br/>
                                <br/>
                                <div className="text-center">
                                    <h2>3- Create Amazing Tokens Creator Platform FREE</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/y8rbrsFmlcs?si=5q9ZxKhDxmRjzcov" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>

                                <br/>
                                <br/>
                                <div className="text-center">
                                    <h2>4- Create Cryptocurrency in 1 Minute</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/RP1OTIqOVRE?si=Ht4hDVOlPvUjhHX9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>

                                <br/>
                                <br/>
                                <div className="text-center">
                                    <h2>5- Create Affiliate Website for Your Token Free</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/U2b-6UaJlnI?si=6YDXtKx3LXQKOy8r" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>

                                <br/>
                                <br/>
                                <div className="text-center">
                                    <h2>6- BNB Divider Smart Contract</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Rtxtl9Ij434?si=r4XNKXUOOgl1lu9L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>

                                <br/>
                                <br/>
                                <div className="text-center">
                                    <h2>7- Create smart contract buy and burn your token automatically</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/GmyRJg8l40w?si=lK6FC-VFLMrMyfV0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>

                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}