import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box } from "../../components/elements";
import { useSearchParams } from "react-router-dom";

export default function BuyBurnContract() {

    const [searchParams] = useSearchParams();

    let scanLink = "https://bscscan.com/address/";

    if (searchParams.get('chain') === "test"){
        scanLink = "https://testnet.bscscan.com/address/";
    }

    if (searchParams.get('chain') === "poly"){
        scanLink = "https://polygonscan.com/address/";
    }

    if (searchParams.get('chain') === "avax"){
        scanLink = "https://snowtrace.io/address/";
    }

    if (searchParams.get('chain') === "ftm"){
        scanLink = "https://ftmscan.com/address/";
    }

    if (searchParams.get('chain') === "core"){
        scanLink = "https://scan.coredao.org/address/";
    }

    return (
        <PageLayout>
            <Box className="mc-blank">
                <h1>Congratulations</h1>
                <h2>Your buy and burn contract has been created</h2>
                <br/>
                <a href={scanLink + searchParams.get('id')} target="_blank" rel="noreferrer"><h3>{searchParams.get('id')}</h3></a>
                <br/>
                <br/>
                <br/>
                Next step : Send BNB to above contract address to buy and burn your token.
                <br/>
            </Box>
        </PageLayout>
    )
}