import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function Solana() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">Solana Project</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={8} xl={8}>
                                <div>
                                    🚀 I’ll create your SPL token on Solana, build a professional project website, and list your token on Pump.fun for maximum exposure. From token creation to website design and listing, I’ve got everything covered to launch your Solana project successfully. Let’s turn your idea into reality! 🌟
                                </div>
                                <br/>
                                <br />
                                <div className="text-center">
                                  <h2 style={{color: 'yellow'}}>"i will invest $200 in your project"</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h1>What You Will Get</h1>
                                </div>
                                <br/>
                                <div><h3>- Main Project Website.</h3></div>
                                <div><h3>- Token on Solana.</h3></div>
                                <div><h3>- Get Listed on Pump Fun.</h3></div>
                                <div><h3>- I will be your advisor during your project.</h3></div>
                                <br/>
                                <div><h3>- Main Website Demo : <a href="https://binancey.com/" target="_blank" rel="noreferrer">https://binancey.com</a></h3></div>
                                <br/>
                                <h4><h3>Price : $250</h3></h4>
                                <br/>
                                <div className="text-center">
                                <a href="https://t.me/mohamedmox" target="_blank" rel="noreferrer"><h3 style={{color: 'yellow'}}>Contact Us</h3></a>
                                </div>
                                <br/>
                            </Col>
                            <Col xs={12} sm={4} xl={4}>
                                <Box>
                                    <div className="text-center">
                                        <img src="images/solana.png" width={"100%"} alt="Solana Logo" />
                                    </div>
                                    <br/>
                                </Box>
                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}
