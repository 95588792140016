import React from "react";
import { Image, List, Box, Text, Item, Anchor, Button } from "../../components/elements";
import { CardLayout } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";

export default function KronobitChain() {
    return (
        <PageLayout>
            <CardLayout className="mb-4">
                <Breadcrumb title="Kronobit Network"></Breadcrumb>
            </CardLayout>

            <CardLayout>
                <List className="mc-notify-list">
                    
                        <Item className="mc-notify-item" key="token">
                            <Anchor className="mc-notify-content" href="/knb-token">
                                <Box className="mc-notify-media">
                                    <Image src="images/coin.png" alt="create token" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Create Your Own ERC20 Token</Text>
                                    <Text as="small">Create an ERC20 Token with liquidity and tax fee on Kronobit Network network in less than a minute, 100% token ownership.</Text>
                                </Box>
                            </Anchor>
                            <Anchor href="/knb-token">
                                <Button className="mc-btn green m-2">Create Token</Button>
                            </Anchor>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                            <Anchor className="mc-notify-content" href="/launchpad-contract">
                                <Box className="mc-notify-media">
                                    <Image src="images/rocket.png" alt="create ico launchpad" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Create ICO Launchpad Contract</Text>
                                    <Text as="small">Create your token ICO presale smart contract on Kronobit Network and start sell your token.</Text>
                                </Box>
                            </Anchor>
                            <Anchor href="/launchpad-contract">
                                <Button className="mc-btn green m-2">Create Contract</Button>
                            </Anchor>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                            <Anchor className="mc-notify-content" href="https://bit.ly/Launchpad-Kronobit">
                                <Box className="mc-notify-media">
                                    <Image src="images/download.png" alt="download ico launchpad" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Download ICO Launchpad Website</Text>
                                    <Text as="small">Download full ready launchpad website, Create your token launchpad or token sale website and start selling your token super easy.</Text>
                                </Box>
                            </Anchor>
                            <div>
                            <Anchor href="https://bit.ly/Launchpad-Kronobit">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                            <br />
                            <Anchor href="https://launchpad.0xfactory.com/" target={'blank'}>
                                <Button className="mc-btn green m-2">Live Demo</Button>
                            </Anchor>
                            </div>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                            <Anchor className="mc-notify-content" href="/airdrop-contract">
                                <Box className="mc-notify-media">
                                    <Image src="images/parachute.png" alt="create airdrop" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Create Airdrop Contract</Text>
                                    <Text as="small">Create airdrop smart contract on Kronobit Network and start distribute your token with claiming fee.</Text>
                                </Box>
                            </Anchor>
                            <Anchor href="/airdrop-contract">
                                <Button className="mc-btn green m-2">Create Contract</Button>
                            </Anchor>
                        </Item>

                        <Item className="mc-notify-item" key="token">
                            <Anchor className="mc-notify-content" href="https://bit.ly/Airdrop-Kronobit">
                                <Box className="mc-notify-media">
                                    <Image src="images/download.png" alt="download airdrop ui" />
                                </Box>
                                <Box className="mc-notify-meta">
                                    <Text as="span">Download Airdrop Website</Text>
                                    <Text as="small">Download full ready airdrop website and earn claiming fee, and increase your token holders now.</Text>
                                </Box>
                            </Anchor>
                            <div>
                            <Anchor href="https://bit.ly/Airdrop-Kronobit">
                                <Button className="mc-btn green m-2">Download</Button>
                            </Anchor>
                            <br />
                            <Anchor href="https://airdrop.0xfactory.com/" target={'blank'}>
                                <Button className="mc-btn green m-2">Live Demo</Button>
                            </Anchor>
                            </div>
                        </Item>
                    
                </List>
            </CardLayout>
        </PageLayout>
    )
}