import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Box, Heading } from "../../components/elements";
import { Container, Row, Col } from "react-bootstrap";

export default function Workshop() {
    return (
        <PageLayout>
            <Container fluid>
                <Heading as="h2" className="mc-overview-title">Crypto Workshop</Heading>
                    <Row xs={1} sm={2} xl={2}>
                            <Col xs={12} sm={8} xl={8}>
                                <div>
                                  Join our Blockchain Intensive Workshop for Beginners, where you will learn all the basic concepts of Blockchain and create your own project yourself during the course.
                                </div>
                                <br/>
                                <div>
                                    <h3>Workshop duration is 5 days, from Wednesday 20 to Sunday 25 November.</h3>
                                </div>
                                <br />
                                <div className="text-center">
                                  <h2 style={{color: 'yellow'}}>A gift for each trainee is a DEX such as PancakeSwap for his project after the end of the workshop.</h2>
                                </div>
                                <br/>
                                <div className="text-center">
                                    <h1>Workshop Topics</h1>
                                </div>
                                <br/>
                                <div><h3>1- Blockchain overview and planning for your project.</h3></div>
                                <div><h3>2- Introduction to Solidity language and smart contract programming.</h3></div>
                                <div><h3>3- Creating digital currencies on different networks.</h3></div>
                                <div><h3>4- Establishing liquidity pools and listing on centralized and decentralized platforms.</h3></div>
                                <div><h3>5- Domain reservation, hosting and dealing with the server.</h3></div>
                                <div><h3>6- Planning for subscription and creating a subscription site to sell the currency.</h3></div>
                                <div><h3>7- Create an airdrop site to distribute the currency.</h3></div>
                                <div><h3>8- Create a trading site to buy and sell currency.</h3></div>
                                <div><h3>9- Post-launch stages of the currency.</h3></div>
                                <div><h3>10- Create a stable digital currency such as USDT and create its own trading platform.</h3></div>
                                <br />
                                <div><h3>Lecture duration: 2 hours daily</h3></div>
                                <br />
                                <div><h3>Location: Dubai</h3></div>
                                <br/>
                                <h4><h3>Price : 1 BNB</h3></h4>
                                <br />
                                <div><h3>Trainer: Mohamed Abdel Razek (More than 10 years of experience in software and 3 years in blockchain development)</h3></div>
                                <br/>
                                <div className="text-center">
                                <a href="https://t.me/mohamedmox" target="_blank" rel="noreferrer"><h3 style={{color: 'yellow'}}>Contact Us to Join</h3></a>
                                </div>
                                <br/>
                            </Col>
                            <Col xs={12} sm={4} xl={4}>
                                <Box>
                                    <div className="text-center">
                                        <img src="images/blockchain2.png" width={"100%"} alt="Social Image" />
                                    </div>
                                    <br/>
                                </Box>
                            </Col>
                    </Row>
                </Container>
        </PageLayout>
    )
}
