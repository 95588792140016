import React from "react";
import { useEffect, useState } from 'react';
import PageLayout from "../../layouts/PageLayout";
import { DivideTitle } from "../../components";
import { Row, Col } from "react-bootstrap";
import { CardHeader } from "../../components/cards";
import { LabelField } from "../../components/fields";
import { Box, Button } from "../../components/elements";


export default function WeiConverter() {

    const [weiText, setWeiText] = React.useState(null);
    const [gweiText, setGweiText] = React.useState(null);
    const [etherText, setEtherText] = React.useState(null);

    const handleWeiChange = (event) => {
        setWeiText(event.target.value);
        setGweiText((Number(event.target.value) / (10 ** 9)).toString());
        setEtherText((Number(event.target.value) / (10 ** 18)).toString());
      };

      const handleGweiChange = (event) => {
        setGweiText(event.target.value);
        setWeiText((Number(event.target.value) * (10 ** 9)).toString());
        setEtherText((Number(event.target.value) / (10 ** 9)).toString());
      };


      const handleEtherChange = (event) => {
        setEtherText(event.target.value);
        setWeiText((Number(event.target.value) * (10 ** 18)).toString());
        setGweiText((Number(event.target.value) * (10 ** 9)).toString());
      };



      useEffect(() => {

      }, [])


    return (
        <PageLayout>
            <Row>
            <Col xl={12}>
                    <Box className="mc-card">
                        <CardHeader title="Ethereum Unit Conversion" />
                        <div>Instantly convert between Wei, Gwei, and Ether with precision using our free, user-friendly Wei Converter tool, designed to simplify your blockchain transactions.</div>
                        <Row xs={12} md={12} xl={12}>
                            <Col>
                                <DivideTitle title=" " className="mb-4 mt-3" />
                                <LabelField onChange={handleWeiChange} value={weiText} label="Wei" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField onChange={handleGweiChange} value={gweiText} label="Gwei" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField onChange={handleEtherChange} value={etherText} label="Ether" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xl={12}>
                    <Box className="mb-4">
                        <Row xs={12} md={3} xl={3} className="justify-content-center">
                        </Row>
                    </Box>
                </Col>
            </Row>
        </PageLayout>
    )
}
